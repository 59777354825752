





















































































































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import FieldRepeater from '@/components/FieldRepeater.vue';
import FieldDate from '@/components/FieldDate.vue';
import PICKUPS from '@/graphql/queries/PickupLocations.graphql';
import BASE_ROUTE from '@/graphql/queries/BaseRoute.graphql';
import COOPS from '@/graphql/queries/Coops.graphql';
import BASE_ROUTE_CREATE from '@/graphql/mutations/BaseRouteCreate.graphql';
import BASE_ROUTE_UPDATE from '@/graphql/mutations/BaseRouteUpdate.graphql';
import { required, decimal } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import { CODES } from '@/utils/constants';
import {
  BaseRouteCreateMutationVariables,
  BaseRouteQuery,
  BaseRouteQueryVariables,
  BaseRouteUpdateMutationVariables,
  CoopsQuery,
  PickupLocation,
  PickupLocationsQuery,
} from '@/types/schema';
import { ApolloQueryResult } from 'apollo-client';
import { GraphQLError } from 'graphql';

export default Vue.extend({
  name: 'AdminRoutesAdd',
  components: {
    AppMain,
    AppForm,
    FieldRepeater,
    FieldDate,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        name: '',
        pickups: [] as { id: string }[],
        miles: '' as string | number,
        effectiveStart: '',
        coops: [] as { id: string }[],
      },
      route: null as BaseRouteQuery['route'],
      pickups: [] as PickupLocationsQuery['pickups'],
      coops: [] as CoopsQuery['coops'],
    };
  },
  computed: {
    availablePickups(): PickupLocation[] {
      return this.pickups.map((pickup) => {
        const disabled = this.form.pickups.some(({ id }) => id === pickup.id);
        return { ...pickup, disabled };
      });
    },
  },
  validations() {
    return {
      form: {
        name: { required },
        pickups: {
          required,
          $each: {
            id: { required },
          },
        },
        miles: { decimal },
        effectiveStart: { required },
        coops: {
          $each: {
            id: { required },
          },
        },
      },
    };
  },
  methods: {
    validationMessages,
    async saveRoute() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        if (this.$route.params.id) await this.updateRoute();
        else await this.addRoute();
        this.$router.replace({ name: 'admin-routes' });
        this.$notify({
          text: 'Route saved!',
          type: 'success',
          duration: 6000,
        });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
    addRoute() {
      return this.$apollo.mutate({
        mutation: BASE_ROUTE_CREATE,
        variables: {
          input: {
            name: this.form.name,
            pickups: this.form.pickups.map((pickup) => pickup.id),
            miles: this.form.miles ?? null,
            effectiveStart: this.form.effectiveStart,
            coops: this.form.coops.map((coop) => coop.id),
          },
        } as BaseRouteCreateMutationVariables,
      });
    },
    updateRoute() {
      if (!this.route) return;

      return this.$apollo.mutate({
        mutation: BASE_ROUTE_UPDATE,
        variables: {
          input: {
            id: this.route.id,
            name: this.form.name,
            pickups: this.form.pickups.map((pickup) => pickup.id),
            miles: this.form.miles ?? null,
            effectiveStart: this.form.effectiveStart,
            coops: this.form.coops.map((coop) => coop.id),
          },
        } as BaseRouteUpdateMutationVariables,
      });
    },
    fillForm(route: BaseRouteQuery['route']) {
      if (!route) return;

      this.form = {
        name: route.name ?? '',
        pickups: route.pickupLocations.map(({ id }) => ({ id })),
        miles: route.miles ?? '',
        effectiveStart: route.effectiveStart ?? '',
        coops: route.coops.map(({ id }) => ({ id })),
      };
    },
  },
  apollo: {
    route: {
      query: BASE_ROUTE,
      variables(): BaseRouteQueryVariables {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }: ApolloQueryResult<BaseRouteQuery>) {
        if (!data) return;
        this.fillForm(data.route);
      },
      error({ graphQLErrors }) {
        const [gqlError] = graphQLErrors;
        if (gqlError?.extensions?.code === CODES.NOT_FOUND) {
          this.$router.replace({ name: 'admin-routes' });
        }
      },
      skip(): boolean {
        return !this.$route.params.id;
      },
      fetchPolicy: 'cache-and-network',
    },
    pickups: {
      query: PICKUPS,
    },
    coops: {
      query: COOPS,
    },
  },
});
