











import Vue from 'vue';
import { Validation } from 'vuelidate';

interface ValidationEach extends Validation {
  $each: {
    $iter: unknown[];
  };
}

export default Vue.extend({
  name: 'FieldRepeater',
  inheritAttrs: false,
  props: {
    value: {
      type: Array as () => unknown[],
      default: () => [],
    },
    v: {
      type: Object as () => ValidationEach,
      default: Object as () => ValidationEach,
    },
  },
  methods: {
    removeItem(index: string | number) {
      const items = this.value.filter((_, i) => i !== parseInt(String(index)));
      this.$emit('input', items);
    },
    addItem(item = { value: '' }) {
      const items = this.value.concat({ ...item });
      this.$emit('input', items);
    },
  },
});
